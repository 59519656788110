import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import detectBrowserLanguage from 'detect-browser-language';

const BrowserLanguageSelector = () => {
  const isBrowser = typeof window !== 'undefined';
  let browserLang = '';
  if (isBrowser) {
    browserLang = detectBrowserLanguage();
  }
  const baseLang = browserLang?.split('-')[0];
  const {
    siteSettings: { siteName, avaliableLanguages, defaultLang },
  } = useStaticQuery(graphql`
    {
      siteSettings {
        siteName
        defaultLang
        avaliableLanguages {
          url
          code
          title
        }
      }
    }
  `);

  const defaultPath = avaliableLanguages.find(({ code }) => code === defaultLang);

  const localPath = avaliableLanguages.find(({ code }) => code.split('-')[0] === baseLang);

  const { url } = localPath || defaultPath || {};

  return (
    <>
      {url ? (
        <Helmet>
          <title>{siteName}</title>
          <script>{`location.href="${url}";`}</script>
        </Helmet>
      ) : (
        <></>
      )}
      <ul>
        {avaliableLanguages.map(({ url: urlDestination, title }) => (
          <li>
            <a href={urlDestination}>{title}</a>
          </li>
        ))}
      </ul>
    </>
  );
};
export default BrowserLanguageSelector;
