import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from 'layout/Layout';
import BrowserLanguageSelector from 'components/BrowserLanguageSelector';
import Footer from 'components/Footer';
import Header from 'components/Header';

import { LanguageSelectorProps } from './models';

const Languageselector: FC<{ data: LanguageSelectorProps }> = ({
  data: {
    footer,
    header,
    siteSettings,
    allHomepage: { nodes },
  },
}) => (
  <Layout headerTransparent>
    <BrowserLanguageSelector />
    {header && <Header data={header} settings={siteSettings} />}
    <ul>
      {nodes.map(({ url, lang }) => (
        <li>
          <Link to={url} key={lang}>
            {lang}
          </Link>
        </li>
      ))}
    </ul>
    {footer ? <Footer data={footer} /> : <></>}
  </Layout>
);

export const query = graphql`
  query ($lang: String) {
    allHomepage {
      nodes {
        url
        lang
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default Languageselector;
